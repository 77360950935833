body {
    line-height: 1.2rem;
}

p {
    margin-bottom: 0.4rem;
    padding-left: 0.2rem;
}

h1 {
    margin-bottom: 0;
}
h2 {
    font-size: 1.5em;
    background-color: #ffdc64;
    margin-top: 1rem;
    padding-left: 0.2rem;
    padding-top: 0.1rem;
    border-top: solid 1px black;
    border-bottom: solid 1px black;
    font-weight: 800;
    line-height: 1.8rem;
    page-break-after: avoid;
}
h3 {
    font-size: 1.2em !important;
    background-color: #fafabd;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    padding-left: 0.2rem;
    padding-top: 0.2rem;
}

ul, ol {
    margin-left: 0.7rem;
    list-style-type: none;
}
