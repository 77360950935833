.small {
    font-size: 90%;
}
.text-grey {
    color: grey;
}

.ml1 {
    margin-left: 1rem
}

.ml2 {
    margin-left: 2rem
}

.mt1 {
    margin-top: 1rem
}

.pbb {
    // break-before does not work with wkhtmltopdf
    page-break-before: always;
}

.youtube-video-container {
    margin: 1rem 0;
    position: relative;
    overflow: hidden;
    width: 100%;
}

.youtube-video-container::after {
    display: block;
    content: "";
    padding-top: 56.25%;
}

.youtube-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

table.compact {
    margin-bottom: 0.5rem;
    th, td {
        padding: 0.4rem 0.5rem 0 0.5rem;
    }
}
