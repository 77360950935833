@media print {
	@page {
		size: A4;
	}


	body {
		font-size: 90%;
	}

	h1 {
		text-align: center;
	}
	h2 {
		font-size: 1.3em;
	}
	
	.site-header {
		border-top: none;
	}
	.site-footer {
		display: none;
	}
	.site-description {
		text-align: center;
	}
	
	a,
	a:visited {
		color: unset;
	}
	a[href].link:after {
		content: ' (' attr(href) ')';
	}

	table.compact {
    margin-bottom: 0.1rem;
    th, td {
        padding: 0.1rem 0.5rem 0 0.5rem;
    }
		p {
			margin-bottom: 0.1rem;
		}
	}

}
