.site-header {
    min-height: unset;
}
.site-title {
    font-variant: small-caps;
    font-size: 2rem;
    line-height: 3rem;
    float: none;
}
.site-description {
    font-size: 1.5rem;
    line-height: 3rem;
}
.site-header {
    border-bottom: 0;
}
.site-nav {
    line-height: 2rem;
}
.site-footer {
    padding: 0.5rem 0;
}

.post {
    break-inside: avoid;
    page-break-inside: avoid;
    text-align: justify;
    .title {
        font-size: 1.2em;
        background-color: #fafabd;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        padding-left: 0.2rem;
        padding-top: 0.2rem;
        // font-weight: 600;
        break-after: avoid-page;
        page-break-after: avoid;

        .post-title {
            font-size: 100%;
            line-height: 1.5;
            display: block;
        }
        .post-subtitle {
            font-size: 80%;
        }
        .post-date {
            font-size: 80%;
            float: right;
        }
    }
}

.page-content {
    padding: 0;
}

.social-media-list {
    columns: 3;
}

// Support of asciidoc table options
table.stripes-none tr {
    background-color: transparent;
}
table.frame-none {
    border: none;
}
table.grid-none {
    td {
        border: none;
    }
}
table td.valign-top {
    vertical-align: top;
}

.footer-col-wrapper {
    margin-left: 1rem;
    margin-right: 1rem;
}

.social-media-image {
    filter: grayscale(1);
}
